<template>
    <client-page>
        <sub-visual visual="검색" subTitle="search" />

        <v-container>
            <div class="main-search mt-0">
                <div class="search-primary search-primary--lg search-primary--gra">
                    <div class="search-primary__form">
                        <v-text-field v-model="keyword" prepend-inner-icon="mdi-magnify" placeholder="'안과이름' 또는 '수술방법' 검색" outlined hide-no-data hide-details @keydown.enter="onEnter" />
                    </div>
                </div>
            </div>
            <template v-if="!searchValue">
                <div class="mt-26 mt-md-36">
                    검색어를 입력해주세요.
                </div>
            </template>
            <template v-else>
                <div class="mt-26 mt-md-36">
                    <search-list-control v-bind="{ items, areasCount, totalCount }">
                        <div> <span class="primary--text font-weight-bold">{{ count?.format?.() || 0 }}</span> 건의 결과를 찾았습니다.</div>
                    </search-list-control>
                    <v-divider class="border-2 grey-2 mt-10 mt-md-20" />
                </div>
                <div class="mt-26 mt-md-36">
                    <v-row>
                        <v-col cols="12" :md="cols" v-for="item in items" :key="item.value">
                            <search-item v-bind="{ item }" />
                        </v-col>
                    </v-row>
                </div>
                <div class="mt-26 mt-md-36" v-if="hasMore">
                    <div class="v-btn--group">
                        <v-btn @click="loadMore" x-large outlined rounded color="primary" class="w-100 mw-180px">더 보기</v-btn>
                    </div>
                </div>
            </template>
        </v-container>
    </client-page>
</template>

<script>
import SubVisual from "@/components/client/sub/sub-visual.vue";
import ClientPage from "../templates/ClientPage.vue";
import SearchListControl from "@/components/client/search/search-list-control.vue";

import api from "@/api";

import sidoGugun from "@/assets/data/sido-gugun.json";
const areas = sidoGugun.flatMap(({ name: sido, gugun }) => [{ text: sido, value: sido, type: "areas" }, ...gugun.map(({ name: gugun }) => ({ text: `${sido} ${gugun}`, value: gugun, sido, type: "areas" }))]);

import { SERVICES } from "@/assets/variables";
import SearchItem from "@/components/client/search/search-item.vue";
const services = Object.values(SERVICES).map((service) => ({ ...service, type: "services" }));

export default {
    components: {
        SubVisual,
        ClientPage,
        SearchListControl,
        SearchItem,
    },
    props: {
        searchValue: { type: String, default: null },
        mode: { type: String, default: "all" },
    },
    data: () => ({
        keyword: null,

        tagsPage: 0,
        locationsPage: 0,
        itemsPerPage: 30,

        tagsCount: undefined,
        locationsCount: undefined,

        areas,
        services,
        tags: [],
        locations: [],

        loading: false,
    }),
    computed: {
        cols() {
            return this.$route.query.cols || 4;
        },
        filteredServices() {
            return this.services.filter(({ text }) => text.includes(this.searchValue));
        },
        filteredAreas() {
            return this.areas.filter(({ text }) => text.includes(this.searchValue));
        },
        servicesCount() {
            return this.filteredServices.length;
        },
        areasCount() {
            return this.filteredAreas.length;
        },
        baseCount() {
            return this.servicesCount + this.areasCount;
        },
        totalCount() {
            return this.baseCount + (this.tagsCount || 0) + (this.locationsCount || 0);
        },
        count() {
            switch (this.mode) {
                case "services":
                    return this.servicesCount;
                case "areas":
                    return this.areasCount;
                case "tags":
                    return this.tagsCount || 0;
                case "locations":
                    return this.locationsCount || 0;

                default:
                    return this.totalCount;
            }
        },
        items() {
            const items = [];

            if (["all", "services"].includes(this.mode)) items.push(...this.filteredServices);
            if (["all", "areas"].includes(this.mode)) items.push(...this.filteredAreas);
            if (["all", "tags"].includes(this.mode)) items.push(...this.tags.map(({ text, _id: value }) => ({ text, value, type: "tags" })));
            if (["all", "locations"].includes(this.mode)) items.push(...this.locations.map(({ name: text, _id: value, address, tags }) => ({ text, value, address, tags, type: "locations" })));

            return items;
        },
        tagsHeaders() {
            const limit = (() => {
                if (this.tagsPage == 1) return this.itemsPerPage - (this.items.length % this.itemsPerPage) || this.itemsPerPage;
                else return this.itemsPerPage;
            })();

            return { skip: this.tags?.length, limit };
        },
        locationsHeaders() {
            const limit = (() => {
                if (this.locationsPage == 1) return this.itemsPerPage - (this.items.length % this.itemsPerPage) || this.itemsPerPage;
                else return this.itemsPerPage;
            })();

            return { skip: this.locations?.length, limit };
        },
        hasMoreTags() {
            return (this.tagsCount || 0) > this.tags.length;
        },
        hasMoreLocations() {
            return (this.locationsCount || 0) > this.locations.length;
        },
        hasMore() {
            if (["all"].includes(this.mode)) return this.totalCount > this.items.length;
            else if (["tags"].includes(this.mode)) return this.hasMoreTags;
            else if (["locations"].includes(this.mode)) return this.hasMoreLocations;
            else return false;
        },
    },
    mounted() {
        if (this.searchValue) this.keyword = this.searchValue;
        this.init().then(() => this.search({ isInitialSearch: true }));
    },
    watch: {
        searchValue() {
            this.init().then(() => this.search({ isInitialSearch: true }));
        },
    },
    methods: {
        async init() {
            this.tags = [];
            this.tagsPage = 0;
            this.tagsCount = undefined;
            this.locations = [];
            this.locationsPage = 0;
            this.locationsCount = undefined;
        },

        async search({ isInitialSearch = false } = {}) {
            if (!!this.searchValue) {
                let { searchValue } = this;

                if (isInitialSearch || this.hasMoreTags) {
                    this.tagsPage += 1;
                    const {
                        summary: { totalCount: tagsCount },
                        tags,
                    } = await api.v1.eyes.tags.gets({
                        headers: this.tagsHeaders,
                        params: { searchValue, sort: "name", withTagSearch: true },
                    });

                    this.tags = this.tags.concat(tags);
                    this.tagsCount = tagsCount;
                }

                if (isInitialSearch || this.hasMoreLocations) {
                    this.locationsPage += 1;

                    const {
                        summary: { totalCount: locationsCount },
                        locations,
                    } = await api.v1.eyes.locations.gets({
                        headers: this.locationsHeaders,
                        params: { searchValue, sort: "name", withTagSearch: true },
                    });

                    this.locations = this.locations.concat(locations);
                    this.locationsCount = locationsCount;
                }
            }
        },
        loadMore() {
            this.search();
        },
        onEnter() {
            if (this.keyword == this.searchValue) this.init().then(this.search);
            else if (!this.keyword) this.$router.push("/search");
            else if (this.mode == "all") this.$router.push(`/search/${this.keyword}`);
            else this.$router.push(`/search/${this.keyword}/${this.mode}`);
        },
    },
};
</script>

<style></style>
