var render = function render(){
  var _vm$count, _vm$count$format;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual', {
    attrs: {
      "visual": "검색",
      "subTitle": "search"
    }
  }), _c('v-container', [_c('div', {
    staticClass: "main-search mt-0"
  }, [_c('div', {
    staticClass: "search-primary search-primary--lg search-primary--gra"
  }, [_c('div', {
    staticClass: "search-primary__form"
  }, [_c('v-text-field', {
    attrs: {
      "prepend-inner-icon": "mdi-magnify",
      "placeholder": "'안과이름' 또는 '수술방법' 검색",
      "outlined": "",
      "hide-no-data": "",
      "hide-details": ""
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.onEnter.apply(null, arguments);
      }
    },
    model: {
      value: _vm.keyword,
      callback: function ($$v) {
        _vm.keyword = $$v;
      },
      expression: "keyword"
    }
  })], 1)])]), !_vm.searchValue ? [_c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_vm._v(" 검색어를 입력해주세요. ")])] : [_c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_c('search-list-control', _vm._b({}, 'search-list-control', {
    items: _vm.items,
    areasCount: _vm.areasCount,
    totalCount: _vm.totalCount
  }, false), [_c('div', [_c('span', {
    staticClass: "primary--text font-weight-bold"
  }, [_vm._v(_vm._s(((_vm$count = _vm.count) === null || _vm$count === void 0 ? void 0 : (_vm$count$format = _vm$count.format) === null || _vm$count$format === void 0 ? void 0 : _vm$count$format.call(_vm$count)) || 0))]), _vm._v(" 건의 결과를 찾았습니다.")])]), _c('v-divider', {
    staticClass: "border-2 grey-2 mt-10 mt-md-20"
  })], 1), _c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_c('v-row', _vm._l(_vm.items, function (item) {
    return _c('v-col', {
      key: item.value,
      attrs: {
        "cols": "12",
        "md": _vm.cols
      }
    }, [_c('search-item', _vm._b({}, 'search-item', {
      item
    }, false))], 1);
  }), 1)], 1), _vm.hasMore ? _c('div', {
    staticClass: "mt-26 mt-md-36"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-100 mw-180px",
    attrs: {
      "x-large": "",
      "outlined": "",
      "rounded": "",
      "color": "primary"
    },
    on: {
      "click": _vm.loadMore
    }
  }, [_vm._v("더 보기")])], 1)]) : _vm._e()]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }