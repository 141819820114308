<template>
    <v-row align="end" class="row--small">
        <v-col cols="2" class="d-none d-md-block">
            <list-control-cols />
        </v-col>
        <v-col cols="4" sm="3" md="2" lg="2">
            <list-control-mode />
        </v-col>
        <v-spacer />
        <v-col cols="auto">
            <slot />
        </v-col>
    </v-row>
</template>

<script>
import ListControlCols from "./list-control-cols.vue";
import ListControlMode from "./list-control-mode.vue";
import ListControlCount from "./list-control-count.vue";
export default {
    components: {
        ListControlCols,
        ListControlMode,
        ListControlCount,
    },
    props: {
        items: { type: Array, default: () => [] },
        totalCount: { type: Number, default: 0 },
    },
};
</script>

<style></style>
