var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type == 'services' ? _c('v-card', _vm._b({
    attrs: {
      "to": {
        path: '/reviews',
        query: {
          keyword: `＃${_vm.item.text}`
        }
      }
    }
  }, 'v-card', _vm.cardAttrs, false), [_c('v-row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pl-16 py-16",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-eye")])], 1), _c('v-col', [_c('v-card-title', [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.item.text))])]), _c('v-card-subtitle', {
    staticClass: "font-size-14"
  }, [_vm._v("진료과목")])], 1)], 1)], 1) : _vm.type == 'areas' ? _c('v-card', _vm._b({
    attrs: {
      "to": {
        path: `/locations`,
        query: {
          sido: _vm.sido,
          gugun: _vm.gugun
        }
      }
    }
  }, 'v-card', _vm.cardAttrs, false), [_c('v-row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pl-16 py-16",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-map")])], 1), _c('v-col', [_c('v-card-title', [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v("＃ " + _vm._s(_vm.item.text) + " 안과")])])], 1)], 1)], 1) : _vm.type == 'locations' ? _c('v-card', _vm._b({
    attrs: {
      "to": {
        path: `/locations/${_vm.item.value}`
      }
    }
  }, 'v-card', _vm.cardAttrs, false), [_c('v-row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pl-16 py-16",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-hospital-box")])], 1), _c('v-col', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.item.text))])]), _c('v-card-subtitle', {
    staticClass: "text-truncate font-size-14"
  }, [_vm._v("안과 / " + _vm._s(_vm.item.address))])], 1)], 1)], 1) : _vm.type == 'tags' ? _c('v-card', _vm._b({
    attrs: {
      "to": {
        path: '/reviews',
        query: {
          _tags: _vm.item.value
        }
      }
    }
  }, 'v-card', _vm.cardAttrs, false), [_c('v-row', {
    staticStyle: {
      "height": "100%"
    },
    attrs: {
      "no-gutters": "",
      "align": "center"
    }
  }, [_c('v-col', {
    staticClass: "pl-16 py-16",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-icon', [_vm._v("mdi-pound")])], 1), _c('v-col', {
    staticStyle: {
      "width": "calc(100% - 40px)"
    }
  }, [_c('v-card-title', [_c('span', {
    staticClass: "text-truncate"
  }, [_vm._v(_vm._s(_vm.item.text))])]), _c('v-card-subtitle', {
    staticClass: "font-size-14"
  }, [_vm._v("안과후기")])], 1)], 1)], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }