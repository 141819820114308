<template>
    <v-select v-model="mode" :items="items" outlined hide-details dense />
</template>

<script>
const items = [
    { text: "전체", value: "all" },
    { text: "진료", value: "services" },
    { text: "지역", value: "areas" },
    { text: "태그", value: "tags" },
    { text: "병원", value: "locations" },
];
export default {
    data: () => ({
        mode: "all",
        items,
    }),
    computed: {
        path() {
            return `/search/${this.$route.params.searchValue}`;
        },
    },
    mounted() {
        this.sync();
    },
    watch: {
        "$route.params.mode"() {
            this.sync();
        },
        mode(mode) {
            const { path } = this;
            if (mode != this.$route.params.mode) {
                if (mode == "all") this.$router.push(path);
                else this.$router.push(`${path}/${mode}`);
            }
        },
    },
    methods: {
        sync() {
            this.mode = this.$route.params.mode || "all";
        },
    },
};
</script>
