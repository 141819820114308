<template>
    <v-card v-if="type == 'services'" :to="{ path: '/reviews', query: { keyword: `＃${item.text}` } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" style="height:100%">
            <v-col cols="auto" class="pl-16 py-16">
                <v-icon>mdi-eye</v-icon>
            </v-col>
            <v-col>
                <v-card-title>
                    <span class="text-truncate">{{ item.text }}</span>
                </v-card-title>
                <v-card-subtitle class="font-size-14">진료과목</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'areas'" :to="{ path: `/locations`, query: { sido, gugun } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" style="height:100%">
            <v-col cols="auto" class="pl-16 py-16">
                <v-icon>mdi-map</v-icon>
            </v-col>
            <v-col>
                <v-card-title>
                    <span class="text-truncate">＃ {{ item.text }} 안과</span>
                </v-card-title>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'locations'" :to="{ path: `/locations/${item.value}` }" v-bind="cardAttrs">
        <v-row no-gutters align="center" style="height:100%">
            <v-col cols="auto" class="pl-16 py-16">
                <v-icon>mdi-hospital-box</v-icon>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title>
                    <span class="text-truncate">{{ item.text }}</span>
                </v-card-title>
                <v-card-subtitle class="text-truncate font-size-14">안과 / {{ item.address }}</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
    <v-card v-else-if="type == 'tags'" :to="{ path: '/reviews', query: { _tags: item.value } }" v-bind="cardAttrs">
        <v-row no-gutters align="center" style="height:100%">
            <v-col cols="auto" class="pl-16 py-16">
                <v-icon>mdi-pound</v-icon>
            </v-col>
            <v-col style="width:calc(100% - 40px)">
                <v-card-title>
                    <span class="text-truncate">{{ item.text }}</span>
                </v-card-title>
                <v-card-subtitle class="font-size-14">안과후기</v-card-subtitle>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
const cardAttrs = {
    height: 75,
    elevation: 6,
    rounded: "xl",
};
export default {
    props: {
        item: { type: Object, default: () => ({}) },
    },
    data: () => ({
        cardAttrs,
    }),
    computed: {
        type() {
            return this.item.type;
        },
        sido() {
            return this.item.sido || this.item.value;
        },
        gugun() {
            if (this.sido == this.item.value) return;
            else return this.item.value;
        },
    },
};
</script>
