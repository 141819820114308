var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--small",
    attrs: {
      "align": "end"
    }
  }, [_c('v-col', {
    staticClass: "d-none d-md-block",
    attrs: {
      "cols": "2"
    }
  }, [_c('list-control-cols')], 1), _c('v-col', {
    attrs: {
      "cols": "4",
      "sm": "3",
      "md": "2",
      "lg": "2"
    }
  }, [_c('list-control-mode')], 1), _c('v-spacer'), _c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_vm._t("default")], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }