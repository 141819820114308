<template>
    <div>{{ count }} 건의 결과를 찾았습니다.</div>
</template>

<script>
const items = [
    { text: "전체", value: "all" },
    { text: "진료항목", value: "services" },
    { text: "지역", value: "areas" },
    { text: "병원", value: "locations" },
];
export default {
    props: {
        items: { type: Array, default: () => [] },
        totalCount: { type: Number, default: 0 },
    },
    computed: {
        mode() {
            return this.$route.params.mode || "all";
        },
        count() {
            switch (this.mode) {
                case "all":
                    return this.items.filter(({ type }) => type !== "locations")?.length + this.totalCount;
                case "locations":
                    return this.totalCount;
                default:
                    return this.items?.length;
            }
        },
    },
};
</script>
