<template>
    <v-select v-model="cols" :items="items" outlined hide-details dense />
</template>

<script>
const items = [
    { text: "1단", value: 12 },
    { text: "2단", value: 6 },
    { text: "3단", value: 4 },
];
export default {
    data: () => ({
        cols: 4,
        items,
    }),
    mounted() {
        this.sync();
    },
    watch: {
        "$route.query.cols"() {
            this.sync();
        },
        cols() {
            let { cols } = this;
            if (cols == 4) cols = undefined;
            if (cols !== +this.$route.query.cols) {
                this.$router.push({ query: { ...this.$route.query, cols } });
            }
        },
    },
    methods: {
        sync() {
            this.cols = +(this.$route.query.cols || 4);
        },
    },
};
</script>
